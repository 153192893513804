'use strict';

// TODO: Remove from `core-js@4`
var $ = require('../internals/export');
var indexed = require('../internals/async-iterator-indexed');

// `AsyncIterator.prototype.asIndexedPairs` method
// https://github.com/tc39/proposal-iterator-helpers
$({
  target: 'AsyncIterator',
  name: 'indexed',
  proto: true,
  real: true,
  forced: true
}, {
  asIndexedPairs: indexed
});