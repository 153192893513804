'use strict';

var $ = require('../internals/export');
var demethodize = require('../internals/function-demethodize');

// `Function.prototype.demethodize` method
// https://github.com/js-choi/proposal-function-demethodize
$({
  target: 'Function',
  proto: true,
  forced: true
}, {
  demethodize: demethodize
});