'use strict';

var $ = require('../internals/export');
var cooked = require('../internals/string-cooked');

// `String.cooked` method
// https://github.com/tc39/proposal-string-cooked
$({
  target: 'String',
  stat: true,
  forced: true
}, {
  cooked: cooked
});