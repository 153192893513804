'use strict';

var $ = require('../internals/export');
var scale = require('../internals/math-scale');

// `Math.scale` method
// https://rwaldron.github.io/proposal-math-extensions/
$({
  target: 'Math',
  stat: true,
  forced: true
}, {
  scale: scale
});