'use strict';

var $ = require('../internals/export');

// `Math.RAD_PER_DEG` constant
// https://rwaldron.github.io/proposal-math-extensions/
$({
  target: 'Math',
  stat: true,
  nonConfigurable: true,
  nonWritable: true
}, {
  RAD_PER_DEG: 180 / Math.PI
});