'use strict';

var $ = require('../internals/export');
var isConstructor = require('../internals/is-constructor');

// `Function.isConstructor` method
// https://github.com/caitp/TC39-Proposals/blob/trunk/tc39-reflect-isconstructor-iscallable.md
$({
  target: 'Function',
  stat: true,
  forced: true
}, {
  isConstructor: isConstructor
});